<template>
	<div>
    <v-card>
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>{{user.fullname}}</v-toolbar-title>
      </v-toolbar>
      <v-tabs :vertical="!isMobilephone">
        <v-tab>
          <v-icon left>
            fa-user
          </v-icon>
          Felhasználó
        </v-tab>
				<v-tab>
					<v-icon left>
						fa-users
					</v-icon>
					Csoportok
				</v-tab>
        <v-tab>
          <v-icon left>
            fa-database
          </v-icon>
          MIR
        </v-tab>
        <!--<v-tab>
          <v-icon left>
            fa-child
          </v-icon>
          Szülő
        </v-tab>-->
				<v-tab>
					<v-icon left>
						fa-trash
					</v-icon>
					Törlés
				</v-tab>

        <v-tab-item> <!--Felhasználó-->
          <v-simple-table>
      			<tbody>
      				<tr>
      					<th style="text-align: right">Név</th>
      					<td colspan="2" style="text-align: center">{{user.fullname}}</td>
      					<!--<td>
      						<v-btn
      							color="blue"
      							@click="dialogus.megjelenik = true, dialogus.modositandoertek=0, dialogus.modositottertek=nev"
      						>
      							Módosítás<v-icon>fa-edit</v-icon>
      						</v-btn>
      					</td>-->
      				</tr>
							<tr v-if="user.MIRClub">
								<th style="text-align: right">Egyesület</th>
								<td colspan="2" style="text-align: center">
									<v-btn text :to="{name: 'club', params: {id: user.MIRClubId}}">{{user.MIRClub.name}}</v-btn>
								</td>
							</tr>
							<tr>
								<th style="text-align: right">Jogkör</th>
								<td colspan="2" style="text-align: center">{{roleNames[user.role]}}</td>
							</tr>
							<tr v-if="user.Region">
								<th style="text-align: right">Vezetett régió</th>
								<td colspan="2" style="text-align: center">{{user.Region.name}}</td>
							</tr>
      				<tr>
      					<th style="text-align: right">Email</th>
      					<td style="text-align: center">{{user.email}}</td>
      					<td>
      						<v-btn
      							color="blue"
      							@click="dialogs.editUser.show = true, dialogs.editUser.changedProperty='email', dialogs.editUser.changedValue=user.email"
      						>
      							Módosítás<v-icon>fa-edit</v-icon>
      						</v-btn>
      					</td>
      				</tr>
      				<tr>
      					<th style="text-align: right">Felhasználónév</th>
      					<td style="text-align: center">{{user.username}}</td>
                <td>
                  <v-btn
                    color="blue"
                    @click="dialogs.editUser.show = true, dialogs.editUser.changedProperty='username', dialogs.editUser.changedValue=user.username"
                  >
                    Módosítás<v-icon>fa-edit</v-icon>
                  </v-btn>
                </td>
      				</tr>
      				<tr>
      					<th style="text-align: right">Jelszó</th>
      					<td style="text-align: center" colspan="2">TITOK</td>
      				</tr>
      			</tbody>
      		</v-simple-table>
        </v-tab-item><!--/Felhasználó-->
				<v-tab-item><!--Csoportok-->
					<groups :leaderId="user.id"/>
				</v-tab-item><!--/Csoportok-->
        <v-tab-item> <!--MIR-->
          <v-btn v-if="user.MIRPerson" color="red accent-4" @click="removeMIRAssociation()">MIR-Összekötés törlése</v-btn>
          <v-btn v-else color="success" @click="dialogs.createMIRAssociation.show=true">MIR-összekötés létrehozása</v-btn>
          <h1 v-if="!user.MIRPerson">A felhasználó MIR-ben tárolt adatainak megjelenítéséhez kösse össze egy MIR-személlyel</h1>
          <div v-else>
            <h1>A felhasználó MIR-ben tárolt adatai</h1>
            <v-simple-table>

              <tbody>
                <tr v-for="d in MIRData" :key="d.value" v-if="d.computed||user.MIRPerson[d.value]">
									<th style="text-align: right">{{d.text}}</th>
									<td style="text-align: center">{{d.computed?computedValue(d.value):user.MIRPerson[d.value]}}</td>
								</tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-tab-item> <!--/MIR-->

				<!--<v-tab-item><--Szülő->
					<div v-if="user.Parent">
						<h1>A felhasználó gyermekei</h1>
						<v-simple-table>
							<thead>
								<tr>
									<th style="text-align: right">Név</th>
									<th style="text-align: center">Törlés</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="kid in user.Parent.KidAthletes">
									<th style="text-align: right">{{kid.lastname}} {{kid.firstname}} {{kid.othername?kid.othername:""}}</th>
									<td style="text-align: center"><v-btn color="red accent-4" @click="removeKid(kid.id)">Törlés<v-icon>fa-trash</v-icon></v-btn></td>
								</tr>
							</tbody>
						</v-simple-table>
					</div>
				</v-tab-item><!-/szülő-->

				<v-tab-item><!--Törlés-->
					<userDelete :userId="user.id"/>
				</v-tab-item>
      </v-tabs>
    </v-card>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogs.editUser.show"
          persistent
          max-width="600px"
        >

          <v-card>
            <v-card-title>
              <span class="headline">{{dialogs.editUser.changedProperty =='fullname'? "Név" : (dialogs.editUser.changedProperty == "email" ? "Email-cím" : (dialogs.editUser.changedProperty== "username" ?  "Felhasználónév": "Jelszó"))}} módosítása</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="dialogs.editUser.changedValue"
                  :label="dialogs.editUser.changedProperty =='fullname'? 'Név' : (dialogs.editUser.changedProperty == 'email' ? 'Email-cím' :  (dialogs.editUser.changedProperty== 'username' ?  'Felhasználónév': 'Új jelszó'))"
                  :prepend-inner-icon="dialogs.editUser.changedProperty =='fullname'  || dialogs.editUser.changedProperty=='username'? 'fa-user' : (dialogs.editUser.changedProperty == 'email' ? 'fa-envelope' : 'fa-key')"
                  :type="dialogs.editUser.changedProperty=='password' ? 'password' : 'text'">
                </v-text-field>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.editUser.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="changeUser()"
              >
                Módosítás
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-row>

  </template>

	</div>
</template>

<script>
	import router from '../router';
	import userDelete from '../components/user-delete'
	import roleNames from '../plugins/roleNames';
	import groups from '../components/groups.vue'

	export default {
    props: ['id'],
		components: {
			userDelete,
			groups
		},
		data: function(){
			return {
        user: {},
        dialogs: {
          editUser: {
            show: false,
  					changedProperty: '',
  					changedValue: "",
          }
        },
				roleNames,
        MIRData: [
          {
            text: "Titulus",
            value: "title",
            computed: false
          },
          {
            text: "Keresztnév",
            value: "firstname",
            computed: false
          },
          {
            text: "Vezetéknév",
            value: "lastname",
            computed: false
          },
          {
            text: "Egyéb név",
            value: "othername",
            computed: false
          },
          {
            text: "Nem",
            value: "gender",
            computed: true
          },
          {
            text: "Születésnap",
            value: "birthdate",
            calculated: false
          },
          {
            text: "Születési ország",
            value: "birth_country",
            calculated: false
          },
          {
            text: "Születési hely",
            value: "birth_city",
            calculated: false
          },
          {
            text: "Nemzetiség",
            value: "nationality",
            calculated: false
          },
          {
            text: "Anyja neve",
            value: "mothers_name",
            calculated: false
          },
          {
            text: "Személyi igazolvány száma",
            value: "id_number",
            calculated: false
          },
          {
            text: "Adóazonosító jele",
            value: "tax_id",
            calculated: false
          },
          {
            text: "Társadalombiztosítási azonosító jele",
            value: "social_security_number",
            calculated: false
          },
          {
            text: "Útlevél száma",
            value: "passport_number",
            calculated: false
          },
          {
            text: "Útlevél érvényessége",
            value: "passport_valid_until",
            calculated: false
          },
          {
            text: "Magasság",
            value: "height",
            calculated: false
          },
          {
            text: "Testtömeg",
            value: "weight",
            calculated: false
          },
          {
            text: "Cipőméret",
            value: "shoe_size",
            calculated: false
          },
          {
            text: "Póló méret",
            value: "shirt_size",
            calculated: false
          },
          {
            text: "Pulóverméret",
            value: "sweater_size",
            calculated: false
          },
        ]
      }
		},
		computed: {
			gender: function() {
				return this.user.MIRPerson.male?"Férfi":"Nő"
			},
		},
		watch: {

		},
		methods: {
      changeUser: function() {
				var changed = {};
				changed[this.dialogs.editUser.changedProperty]=this.dialogs.editUser.changedValue;
				this.axios({url: "user/"+this.$route.params.id,method: "PUT", data: {...changed}}).then((response) => {
					this.dialogs.editUser.show=false;
					this.user[this.dialogs.editUser.changedProperty] = this.dialogs.editUser.changedValue
					this.dialogs.editUser.changedProperty=''
					this.dialogs.editUser.changedValue='';
					this.$store.commit('setSnack','A módosítás sikeresen megtörtént')
				})
      },
			removeMIRAssociation() {
				this.dialogs.editUser.changedProperty="MIRPersonId";
				this.dialogs.editUser.changedValue=null;
				this.changeUser();
			},
			computedValue: function(value) {
				return this[value]
			}
		},
		mounted(){
			this.axios({url: "user/"+this.$route.params.id, method: "GET", params: {full: true}}).then((response) => {
        if(response.data.success) {
          this.user=response.data.data;
        }
			})
		}
	}
</script>
